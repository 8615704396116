

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* App.css */

.App {
  display: flex;
  height: 100vh; 
font-family: Inter, sans-serif;
text-align: center;
background-color: #f4f5f7;
}

.Sidebar {
  flex: 0 0 200px; /* Adjust width as needed, this sets the sidebar width */
  /* Add any additional styling for your sidebar here */
}

.Content {
  flex-grow: 1; /* This allows the content area to take up the remaining space */
  padding: 20px; /* Add padding if desired */
  overflow: auto;
  width: 100%;
}
.Content h1{
  margin: auto;
}






.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  width: 100vw;
  height: 100vh;
  /* padding: 20px; */
  background-color: #f4f5f7;
  color: #333;
  overflow: hidden;
}

.card {
  background-color: #fff;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.file-list {
  margin-top: 20px;
}
/* .s3bucket{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  border-radius: 5px;
  margin-top: 90px;
  background-color: #fff;
} */

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
}

#uploadButton{
  margin-left: 10px;
} 







.label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-align: left;
 
}
.file-actions-icon{
  display: flex;
  flex-direction: row;
  padding: 0;
 margin-left: auto;
 margin-bottom: auto;
 margin-top: auto;
 gap: 20px;
}

.rdslabel,.s3label{
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
}

#fileUpload {
  margin-bottom: 10px;
}

#quicksightDashboard iframe {
  width: 100%;
  border: none;
  height: 600px;
}

button {
  background-color: #1f4f8f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.refrest-btn{
  float: right;
}
button:hover {
  background-color:  #163f75;
}
.s3-rds-container {
  display: flex;
  justify-content: space-between; /* Adjusts space between cards */
  gap: 20px; /* Adds space between cards */
}
.files-card {
  background-color: #fff;

 margin: 0 10px;
  border-radius: 5px;
  flex: 1; 
 
}


.refresh-btn, .icon-container i {
  margin-left: 10px;
  cursor: pointer;
  background: none;
  border: none;
}



#sendIcon{
  margin-top: 0px;
}


#selectedFilesContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

#selectedFilesContainer span {
  margin-right: 10px;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
}
#cancelButton{
  background-color: #fff;
  border: 1px solid black;
  color: black;
  transition: 0.3s;
}
#cancelButton:hover{
  background-color: rgb(247, 25, 25);
  color: #fff;
  border: 1px solid rgb(247, 25, 25);
}












.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid grey; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Center in the div */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.file-item {
  cursor: pointer;
  
}


.file-item button{
  padding: 5px;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  transition: 0.3s;
}
.file-item button:hover{
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  color: #1f4f8f;
}
.create-options {
  position: absolute;
  background-color: #f9f9f9;
  top: 110%;
  min-width: 130px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.create-options ul {
  list-style-type: none;
  padding: 0;
}
.create-options ul li {
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
}
.create-options ul li:hover {
  background-color: #f1f1f1;
}
