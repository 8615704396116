.all-locker-items{
    padding: 10px 15px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #fff;
}
.locker-item-icon{
    margin-top: auto;
    margin-bottom: auto;
   width: 30px;
margin-right: 0;
   font-size: 20px;
  /* background-color: aqua; */
}
.locker-icon{
    margin-left: auto;
    margin-right: auto;
}
.locker-item-container{
    margin-left: 10px;
    margin-right: 20px;
  
}
.locker-item-container:hover .locker-item-icon{
    color: #1f4f8f;
}
.locker-result-details{
    margin-left: 0;
    margin-top: auto;
}
.table-list .locker-item-name{
    margin-right: 10px;
 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rdsfiles .all-items-header{
    display: grid;
    grid-template-columns: 66% 17% 17%;
}
.rdsfiles .allitems-item{
    display: grid;
    grid-template-columns: 66% 17% 17%;
}
.rdsfiles .allitems-last-update{
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
}
.allitems-actions-header{
    text-align: left;
    margin-left: 20px;
}
.all-items-search-bar{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;

}