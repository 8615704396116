.search-navigation-bar{
  margin-top: -10px;
  text-align: left;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 20px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.search-lable{
  font-size: 25px;
  text-align: left;
  padding: 20px 0;
  font-weight: bold;
}
.navigation-bar {
    display: flex;
    border-bottom: 1px solid transparent;
    margin-bottom: 10px;
    gap: 50px;
  
    /* border-radius: 5px; */
  }
  
  .nav-item {
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent; /* Ensures the layout does not change when becoming active */
    transition: all 0.3s;
  }
  
  .nav-item.active {
    color: #1f4f8f; /* Change as needed */
    border-bottom: 2px solid #1f4f8f; /* Change as needed */
  }
  

  @media screen and (max-width: 900px) {
    .navigation-bar {
        gap: 30px;
    }
}

@media screen and (max-width: 600px) {
    .navigation-bar {
        gap: 20px;
    }
    .nav-item {
      font-size: 14px;
    }
}