.selectedLocker-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
 
  /* background-color: aqua; */
}
.selectedLocker-header{
    display: flex;
    flex-direction: column;
   
}

.selectedLocker-container p {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;

}
.selectedLocker-container .locker-name{
    margin-left: 30px;
}

.tab-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:30px;
    color: black;
    /* border: 0.5px solid #1f4f8f; */
    margin-top: 20px;
    margin-left: 15px;
    /* border-radius: 100px; */
    font-size: 14px;


}

.tab {
    /* border-right: 0.5px solid #1f4f8f; */
    padding: 12px 2px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease;
    margin-left: 10px;
}
.tab:not(.selected):hover {
    /* background-color: #ddd;  */
    color: #1f4f8f;
}

/* Style for selected tab */
.selected {
    /* background-color: #1f4f8f; */
    color: #1f4f8f;
   border-bottom: 2px solid #1f4f8f;
}

/* Optionally, you can define a specific style for hover on selected if needed, 
   but here we are not changing on hover to meet the requirement */
.selected:hover {
    /* background-color: #1f4f8f !important;  */
    cursor: default;
}



#myLinks-tab {
    border-right: 0;
    /* border-top-right-radius: 100px;
    border-bottom-right-radius: 100px; */
}


.selected-locker-name:hover{
   color: black;
   text-decoration: none;
   cursor:default;
}

.selected-locker-icon{
    color: black;
}
.selected-locker-name{
    font-size: 14px;
}
.create-new{
    margin-left: auto;
    margin-right: 20px;
    position: relative;
    display: inline-block;
    gap:20px;
}


.create-new .btn-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    padding: auto;
  }
  .create-new .create-new-btn {
    position: relative;
    background-color: transparent;
    color: #1f4f8f;
    border: 1px solid #1f4f8f;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
   font-size: 16px;
    margin:4px 0;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden; 
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  .create-new .create-new-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%; /* Start with no fill */
    background-color: #1f4f8f; /* Background color to fill on hover */
    transition: height 0.3s ease; /* Animation effect from bottom to top */
    z-index: -1; /* Ensures the text stays on top */
  }
  .create-new .create-new-btn:hover::after {
    height: 100%; /* Full fill on hover */
  }
  .create-new .create-new-btn:hover{
    color:white;
  }
  .create-new .create-new-btn span {
    margin-right: 5px;
    font-size: 18px;
  }

  
  
  .create-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .create-button span {
    margin-right: 5px;
  }
  
  .create-new .dropdown {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    right: 100%;
    top:  0%;
   
  }
  
  .create-new .dropdown p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .create-new .dropdown p:hover {
    background-color: #f1f1f1;
    
  }
  .create-new .dropdown p{
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .create-new-icon{
    margin-right: 10px;
  }
  .locker-name-create-btn{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-input-container {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    width: 500px;
}

.link-input-container h4 {
    margin-top: 0;
    text-align: left;
    margin-left: 15px;
}

.link-input-container input {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border:1px solid #ccc
}

.link-input-container .upload-btn {
    background-color: #1f4f8f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.create-new .link-input-container .close-link-overlay-btn {
  background-color: #ccc;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: auto;
    transition: all 0.3s ease;
}
.create-new .link-input-container .close-link-overlay-btn:hover{
  background-color: #f44336 !important;
  color: white;
  margin-left: auto;
  
}
.link-btn-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
  margin-left: auto;
 

}
.selectedLocker-container.dragging {
  border: 2px dashed #1f4f8f;
}