.profile-dashboard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin: auto;
    height: 100vh;
    background-color: #f5f7fa;
    font-family: 'Arial', sans-serif;
   
  }
  .profile-container-wrapper{
    margin-top: 20px;
  }
  .profile-container-wrapper h3{
    text-align: left;
  }
  
  .profile-left-panel {
    width: 350px;
    height: auto;
    background-color: #ffffff;

   
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  }
  
  .profile-summary {
    text-align: center;
    padding-top: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    width: 100%;
  
  }
  
  .profile-image {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .camera-icon {
    position: absolute;
    bottom: 0;
    right: 0;
   border: 2px solid #fff;
    background-color: #1f4f8f;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
  }
  
  .profile-left-panel h2 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
  }
  
  .profile-dashboard nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding: 10px;
  }
  
  .profile-dashboard .nav-item {
    text-align: left;
    padding: 12px 25px;
    text-decoration: none;
    color: #1f4f8f;
    opacity: 0.5;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: background-color 0.3s, color 0.3s;
    width: calc(100% - 50px);
  }
  .profile-dashboard .navbar-item-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    width: 13px;
    height: 13px;
   
  }
  
  .profile-dashboard .nav-item:hover {
    background-color: #e9ecef;
  }
  
  .profile-dashboard .nav-item.active {
    background-color: #e9ecef;
    color: #1f4f8f;
    opacity: 0.5;
    border: none;
  }
  
  .profile-right-panel {
    flex-grow: 1;
    padding: 30px;
    overflow-y: auto;
    padding-top: 0;
    margin-top: 0;
  }
  .edit-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    padding-top: 0;
  }
  
  .edit-profile h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .edit-profile p {
    margin-bottom: 30px;
    color: #666;
  }
  
  .edit-profile-form-group {
    margin-bottom: 20px;
  }
  
  .edit-profile-form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    text-align: left;
    font-size: 14px;
  }
  
  .edit-profile-form-group input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
    gap: 10px;
  
 
  }
  
  .button-group button {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
    margin-left: 0;
  }
  
  .update-profile {
    background-color: #1f4f8f;
    color: white;
    width: auto;
   
    margin-left: 0;
  }
  
  .update-profile:hover {
    background-color: #1f3f8f;
  }
  
  .buy-number {
    background-color: #eee;
    border: 1px solid #007bff;
    color: #000;

  }
  
  .buy-number:hover {
    background-color: #ddd;
    color: #000;
  }
  .edit-profile-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    background-color: #fff;
    padding: 0 20px;
    padding-top: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  .edit-profile-header h4{
    margin-bottom: 0;
  }
  .edit-profile-header p{
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .edit-profile-form{
    margin-top: 20px;
  }
  
  
  @media (max-width: 768px) {
    .dashboard {
      flex-direction: column;
    }
  
    .left-panel {
      width: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  
    .right-panel {
      padding: 20px;
    }
  }