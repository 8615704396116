.filesBtn{
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 margin-left: 25px;
}
.cancelBtn{
    background-color: red;
}
/* FileUpload.css */
.progress-bar {
    width: 100%;
    background-color: #eee;
    position: relative;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
}
.file-upload{
    display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;


}
.progress {
    width: 100%; /* Ensure this covers the full area of the progress-bar */
    height: 100%;
    background-color: #ddd; /* Base color of the progress */
    position: absolute;
    top: 0;
    left: 0;
}

.animated-progress {
    height: 100%;
    width: 25%; /* Represents the 25% colored segment */
    background-color: #1f4f8f; /* Color of the animated segment */
    position: absolute;
    animation: moveProgress 2s linear infinite;
}

@keyframes moveProgress {
    0% {
        left: -25%; /* Start from outside the left boundary */
    }
    100% {
        left: 100%; /* Move past the right boundary */
    }
}

.uploadingFiles{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    width: 300px;
    margin-left: 25px;
}
.uploadingFiles p{
    font-size: 12px;
    margin-top: 0;
}
.success-message {
    animation: slideInRightToLeft 0.5s ease-out forwards;
    padding: 20px;
    background-color: #4CAF50; 
    color: white; 
    margin: 10px 0;
    border-radius: 4px;
    font-weight: 600;
    top: 50px;
    right: 30px;
    position: fixed; 
    z-index: 99999;
  }
  .error-message{
    animation: slideInRightToLeft 0.5s ease-out forwards;
      padding: 20px;
      background-color: #f44336; 
      color: white; 
      margin: 10px 0;
      border-radius: 4px;
      font-weight: 600;
      top: 50px;
      right: 30px;
      position: fixed; 
      z-index: 99999;
  }
 


 

.drag-drop-label {
    opacity: 0.5;
    cursor: pointer;
    margin: auto;
    margin-bottom: -30px;
    margin-top: 10px;
    font-size: 12px;
    padding: 10px 20px;
    border: 2px dashed #aaa; 
    border-radius: 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    
}
#uploadButton{
    background-color: #4CAF50;
    border: 1px solid transparent;
}
#uploadButton:hover{
background-color: #3b953e;
}
#cancelButton:hover{
    background-color: #f44336;
    border: 1px solid transparent;
    color: white;
}
#selectedFilesContainer{
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    
}

@media screen and (max-width: 600px) {
    .drag-drop-label {
        display: none;
    }
}

  