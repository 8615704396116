table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 10px;
}

.shared-table-header {
    display: grid;
    grid-template-columns: 46% 18% 18% 18%;
    padding: 5px 10px;
    margin-bottom: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    background-color: #f8f9fb;
    border-bottom: 2px solid #eaeaea;
    border-radius: 10px;
    font-size: 14px;

}
.shared-table-body{
    display: grid;
    grid-template-columns:46% 18% 18% 18% ;
     text-align: left;
     font-size: 12px;
}
.shared-item-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-left: 10px;
    margin-top: auto;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.shared-item-name:hover{
    color: #1f4f8f;
    text-decoration: underline;

}
.shared-table-header .shared-item-header{
    font-weight: 500 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
   
    
}
.shared-item-details{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.shared-item-action{
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: auto;
    margin-top: auto;
    gap: 5px;
    margin-right: 30px;
   margin-left: 10px;
}
.shared-item-action button{
  background-color: transparent;
  font-size: 12px;
  color: black;
  padding: 5px;
}
.shared-item-action button:hover{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   color: #1f4f8f;
}

.shared-item-icon{
    font-size: 16px;
}

@media screen and (max-width: 850px) {
    .shared-item-owner {
      display: none;
    }
    .shared-item-action{
        text-align: right;
    }
    .shared-table-body{
        grid-template-columns:60% 20%  20% ;
    }
    .shared-table-header {
        grid-template-columns:60% 20%  20% ;
    }
  }
  @media screen and (max-width: 650px) {
    .shared-table-body{
        grid-template-columns:50% 25%  25% ;
    }
    .shared-table-header {
        grid-template-columns:50% 25%  25% ;
    }
  }
