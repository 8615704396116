.onepgr-save-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .onepgr-save-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .onepgr-save-modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
  }
  
  .onepgr-input-group {
    margin-bottom: 20px;
  }
  
  .onepgr-input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .onepgr-input-group input {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .onepgr-button-group {
    display: flex;
    justify-content: flex-end;
  }
  
  .onepgr-button-group button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .onepgr-cancel-btn {
    background-color: #f0f0f0;
    color: #333;
    margin-right: 10px;
  }
  
  .onepgr-cancel-btn:hover {
    background-color: #e0e0e0;
  }
  
  .onepgr-save-btn {
    background-color: #1f4f8f;
    color: white;
  }
  
  .onepgr-save-btn:hover {
    background-color: #1f3f8f;
  }