/* NavBar.css */
body{
    padding: 0;
    margin: 0;
}
.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
   height: 74px;
    justify-content: space-between;
    align-items: center;
    background-color: #1f4f8f;
    padding: 0 20px; 
    z-index: 999;
}
.logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 26px;
    margin-left: 30px;
}
  
  .logo img {
    max-height: 40px; 
  }
  .ViewFileComponent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .view-file-page{
    margin: 90px 20px 20px 20px;
    height: 85vh;
    width: 91vw;
    overflow: auto;
  }
  .view-file-content{
    width: 89.6vw;
    height: 97%;
    overflow: auto;
    display: flex;
    /* This will help you center the content */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    padding: 10px;
    background-color: white;
  }
  .view-file-content iframe{
  width: 100%;
   height: 100%;
}
