.shared-with-me-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    
}
.shared-with-me-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
}
.shared-with-me-header p{
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;    
    
    font-weight: 600;
}
.shared-with-me-tab-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:30px;
    color: black;
    background-color: #fff;
    /* border: 0.5px solid #1f4f8f; */
    border-radius: 5px;
    font-size: 14px;
}

.shared-with-me-tab{
    padding: 12px 2px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease;
    margin-left: 20px;
    border-bottom: 2px solid transparent;
}
.shared-with-me-tab.active{
   color: #1f4f8f;
   border-bottom: 2px solid #1f4f8f;
}
.shared-with-me-tab-content{
    margin-top: 10px;
    margin-left: 0;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
}