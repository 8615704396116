
.sidebar {
    width: 270px;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    transition: all .3s ease;
  }

.sidebar .sidebar-menu span{
  margin-left: 0;
  transform: translate(10px);
  transition: all .2s ease;
}

/* .sidebar .sidebar-menu .head-label-text:after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor; 
} */

.head-label-text{
  margin-right: 5px;
}

.font-icon  {
  
  color: #adb4d2;
  transition: all .3s ease;
  
}
.submenu .font-icon{
  width: 14px;
  height: 14px;
 
}


  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin-top: 90px;
    transition: all .3s ease;
  }
  .submenu{
    text-align: left;
   margin-left: -18px;
   margin-right: 5px;
   font-size: 12px !important;

  }
  .sidebar-menu li {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 5px;
    color :rgba(0, 0, 0, .87);
    font-size: 14px ;
    transition: 0.3s ease;
    border-right: 2px solid transparent;
    overflow: hidden;
  }
  .submenu li{
    font-size: 14px ;
    transition: all .3s ease;
    margin: 0;

  }
  .submenu li:hover{
    box-shadow: none;
    
  }
  .sidebar-menu li:hover{
    color: #1f4f8f ;
    
  }
  .sidebar-menu li.active .font-icon, li:hover .font-icon {
    color: #1f4f8f ;
   
  }
  
  .sidebar-menu li.active {
    background-color: #5f63f20d;
    color: #272b41;
    border-right: 2px solid #1f4f8f;
    /* padding-left: 30px; */
  }
  .sidebar-menu .nested-submenu li.active .font-icon{
    margin-left: 20px;
   
  }
 
  .sidebar-trial {
    margin-top: auto;
    padding: 1rem;
    text-align: center;
  }
  
  .trial-info {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .trial-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .trial-upgrade-btn {
    width: 100%;
    padding: 0.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  


.sidebar.collapsed {
  width: 95px; 
  padding-right: 10px;
  transition: all .3s ease;
}
.sidebar.collapsed:hover {
  width: 260px; 
}

.sidebar-menu li .font-icon {
  display: inline-block; 
  font-size: 16px;
  width: 16px;
}

.sidebar.collapsed .sidebar-menu li span {
  display: none;
  transition: all .2s ease;
  font-size: 14px;
}


.sidebar.collapsed .submenu .locker-item{
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.sidebar.collapsed .submenu .locker-item .locker-name-section{
  display: flex;
  flex-direction:row;
  align-items: flex-start;
}
.sidebar-locker-name{
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
   width: 120px;
}
.sidebar.collapsed  .sidebar-menu li.active{
  background-color: rgba(95, 99, 242, .05);
  /* border-radius: 50%; */
  border: none;
  
}
.sidebar.collapsed  .sidebar-menu li{
  /* border-radius: 50%; */
  border: none;
  font-size: 16px;
}
.sidebar.collapsed  .sidebar-menu span{
  font-size: 16px;
}
.sidebar.collapsed  .submenu span{
  font-size: 14px;
}
.sidebar.collapsed  .nested-submenu li{
  font-size: 16px;
  padding-left: 0px;
  width: 20px;
  height: 20px;
 
}
.sidebar.collapsed  .nested-submenu li.active .font-icon{
  border-radius: 50%;
margin-left: 15px;
margin-top: auto;
margin-bottom: auto;
}

.sidebar.collapsed  .submenu .locker-item .font-icon{
  margin-left: 4px;
  
}
.sidebar.collapsed  .add-locker span{

display :none
}
.sidebar.collapsed:hover  .submenu .font-icon{
  margin-left: 0px;
}
.sidebar.collapsed:hover .locker-item {
  margin-left: 0px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px;
}
.sidebar.collapsed:hover .locker-item  .locker-name-section{
  display : flex;
  flex-direction: row;
  align-items: flex-start;
 

}

.sidebar.collapsed .sidebar-menu .nested-submenu li{
 border-radius: 50%;
}

.sidebar.collapsed:hover .sidebar-menu .nested-submenu li{
  display : flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;

}
.sidebar.collapsed:hover .sidebar-menu .nested-submenu li.active{
  background-color: #5f63f20d;
  color: #272b41;
  border-right: 2px solid #1f4f8f;
  border-radius: 0px;
  width: 110px;
}
/* .sidebar.collapsed:hover .sidebar-menu .nested-submenu  li.active .font-icon{
  margin-left: 20px;
} */

.sidebar.collapsed:hover .sidebar-menu li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 5px;
  color :rgba(0, 0, 0, .87);
  font-size: 16px ;
  transition: 0.3s ease;

}
.sidebar.collapsed:hover .sidebar-menu span {
display: block;
}

.locker-item{
display: flex;
flex-direction: column;
}
.locker-name-section{

 display: flex;
 flex-direction: row;
 align-items: flex-start;
 gap: 10px;
}
.submenu .locker-item{
margin-left: 10px;
display: flex;
align-items: flex-start;
padding: 0 ;

}
.nested-submenu{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -15px;
  font-size: 12px;
  
}
.nested-submenu li{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  width: auto;
  background-color: aqua;
 
}
.submenu .add-locker {
  position: relative; /* Required to position the pseudo-element absolutely within the button */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #1f4f8f;
  color: #1f4f8f;
  padding: 5px 10px;
  overflow: hidden; /* Ensures the pseudo-element does not extend outside the button */
  transition: color 0.3s ease; /* Transition for text color */
  z-index: 1;
}

.submenu .add-locker::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%; /* Start with no fill */
  background-color: #1f4f8f; /* Background color to fill on hover */
  transition: height 0.2s ease; /* Animation effect from bottom to top */
  z-index: -1; /* Ensures the text stays on top */
}

.submenu  .add-locker:hover::after {
  height: 100%; /* Full fill on hover */
}

.submenu  .add-locker:hover {
  color: white; 
  
}

.submenu .add-locker p {
  position: relative; /* Ensures the text is above the pseudo-element */
  font-size: 16px;
  margin-right: 2px;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 0.3s ease;
  /* Also necessary to keep the text above the background */
}
.add-locker span{
  margin-right: 5px;
  padding-right:  10px;
 
}

.submenu {
  display: none;
}

.submenu.expanded {
  display: block;
}
.sidebar-menu .arrow{
  text-align: center;
  background-color: aqua;
  padding-left: 0 !important;
  margin-left:-10px !important;
}
.locker-submenu{
  margin-left: -25px;
  max-height: 350px;
  overflow: auto;
}
.locker-submenu li{
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 0;
 padding:  5px 0 !important;
}
.sidebar-locker-head{
  margin-right: 5px;
}


@media screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }
}



