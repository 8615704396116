
.upload-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
  }
  
  .upload-overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
  }
  #fileName{
    width: calc(100% - 20px);
    height: 20px;
    margin: 10px ;
    margin-right: 10px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .form-group {
   
    margin-bottom: 15px;
  }
  .dropdown-file-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #optionSelect{
    margin-left: 10px;
    width: calc(100% - 10px);
    padding: 3px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .button-group button {
    margin-left: 10px;
  }
  .cancle-btn-uploadoverlay{
    background-color: #ccc;
    color: black;
    transition: all 0.3s ease-in-out;
  }
  .cancle-btn-uploadoverlay:hover{
    background-color: #f44336 ;
    color: white;
  }