.link-search-content{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    /* height: 530px; */
    width: calc(100% - 20px);
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}
.link-search-results-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    

}
.result-link-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    width: 100%;
}
.links-search-results{
    margin-top: 50px;
}
.link-search-content .link-search-bar{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
}
.link-search-content .link-search-bar label{
    margin: auto;
    margin-right: 10px;
    color: #1f4f8f;
}
.link-search-content .link-search-bar input{
    width: 200px !important;
   
}
.Link-search-result-date{
    font-size: 12px;
    margin-left: 10px !important;
}
.Link-search-last-updated{
  
    width: 100%;
}
.Link-search-item{
    margin-left: -15px !important;
}

.Link-search-item-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px !important;
}
.Link-search-results-header{
    display: grid;
    grid-template-columns: 82% 18% !important;
   
}
.Link-list{
    margin-top: -15px !important;
    margin-bottom: -15px !important;
}