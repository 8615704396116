.api-keys-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .api-keys-title {
    color: #888;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 0;
  }
  
  .api-provider {
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .provider-name {
    font-size: 14px;
    margin-bottom: 0;
    
  }
  
  .api-inputs {
    display: flex;
    gap: 10px;
  }
  
  .api-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .save-button {
    padding: 10px 20px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #888;
  }
  
  .save-button:hover {
    background-color: #d0d0d0;
  }
  .save-button.active {
    background-color: #1f4f8f;
    color: white;
  }
  
  .save-button.active:hover {
    background-color: #1f4f8f;
    color: white;
  }
  /* Tooltip styles */
.custom-tooltip {
  max-width: 200px !important;
  word-break: break-word;
}

/* Ensure the tooltip text is visible */
.custom-tooltip .tooltip-inner {
  padding: 8px;
  font-size: 12px;
  line-height: 1.4;
}

/* Custom arrow styles to match the tooltip */
.custom-tooltip .arrow::before {
  border-top-color: #ced4da !important;
}

/* Style for the info icon */
.info-icon-wrapper {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.info-icon {
  font-size: 16px;
  color: #1f4f8f;
  
}

/* Optional: Add a hover effect to the info icon */
