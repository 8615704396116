/* Pagination.css */
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #f8f9fb;
    border-top: 2px solid #eaeaea;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .items-per-page {
    display: flex;
    align-items: center;
  }
  
  .items-per-page label {
    margin-right: 5px;
    font-size: 14px;
  }
  
  .items-per-page select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
  }
  
  .pagination-info {
    font-size: 14px;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
  }
  
  .pagination-controls button {
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-controls button:not(:disabled):hover {
    background-color: #e0e0e0;
  }
  
  .pagination-controls svg {
    width: 20px;
    height: 20px;
  }
  .controllsIcon{
    color: black;
  }

  #tooltip-double-left, #tooltip-single-left, #tooltip-single-right ,#tooltip-double-right{
    background-color: #1f4f8f; 
    color: white; 
    border-radius: 5px;
    padding: 2px 5px;
    z-index: 99999;


}