.table-view-component{
    margin-top: 10px ;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    background-color: #fff;
    height: 90vh;
 border-radius: 5px;
 height: auto;
 width: 100%;
}

.notes-save{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:30px;
    margin-left: auto;
  
  }
  .notesvisible{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: flex-start; */
    margin-left: auto;
    margin-right: 10px;

   
  }
  .sqlTextArea{
    display: flex;
 
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0 30px 0;
    gap:10px;
  }
  .sqlTextArea textarea{
    height: 90px;
    width: 655px;
    border-radius: 5px;
    resize: none;
  }
  .clipboard-icon{
    margin-top: 10px;
    margin-right: 0px;
    color: #1f4f8f;
    font-size: 26px;
    cursor: pointer;
    padding: 0;
  }
  .tableNameHead{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
   
   
  }
  
  .tableNameHead h4{
    text-align: left; 
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 12px;
    color: #1f4f8f;
    font-size: 18px;
    width: calc(100% - 200px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }
  .tableNameHead h3{
    width: auto; 
    height: auto; 
   margin-top: auto;
   margin-bottom: auto;
  }
  .table-content{
    width:100%;
    height: 55vh;
    overflow: auto;
    margin-top: 3px;
 
  }

  .table-view th, .table-view td {
    border: 0.5px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 100px !important;
    min-width: 100px;
}
.table-view th:first-child,
.table-view td:first-child {
    min-width: 30px;
    white-space: normal;
    word-wrap: break-word;
    position: sticky;
    left: 0;
    z-index: 100;
    background-color: #fff;
}
.table-view th:first-child{
  background-color: #1f4f8f;
}


/* .table-view tr:nth-child(even) {
    background-color: #f2f2f2; 
} */

.table-view th {
  background-color: #1f4f8f; 
  color: white;

}
.table-view thead{
  background-color:deeppink;
  position: sticky;
  top: 0; /* Adjust this value based on your needs */
  z-index: 10000 !important; 
}
.table-view {
  /* width: auto; */
  border-collapse: separate;
  border-spacing: 0;
    background-color: #fff; 
    padding-top: 0;
   
}

 
.tables-view-content {
    padding: 20px;
  
  }
  .save-csv-icon{
    margin-top: 20px;
    margin-bottom: 0;
   margin-left: auto;
   color: white;
   font-size: 12px;
   
  }
  .table-information{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
  }


  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    font-size: 10px;
    
  }
 
  
  th, td {
    /* border: 1px solid #ddd; */
    padding: 8px;
    text-align: left;
    
  }
  .results-table-content thead{
    background-color: #ccd3dd;
    border-radius: 10px ;
    position: sticky;
    top: 0; 
    z-index: 10; 
    color: white;
  }
  
 
   /* tr:nth-child(even) {
    background-color: #f2f2f2; 
} */
.search-result{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    background-color: #fff;
    padding: 20px;
    margin-top: 70px;
    border-radius: 5px;
    height: auto;
  
}
.search-result h3{
    text-align: left;
    font-size: 25px;
    margin-bottom: 2px;
}
.search-result h3 span{
    color: #1f4f8f;
}
.search-result h4{
 margin-top: 0;
 margin-bottom: 0;
}
 .appliedFilters{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    margin-top: 25px;
   
}


.appliedFilters p{
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: bold;
}
.results-table-content{
    width:100%;
    height: 67vh;
    overflow: auto;
    margin-top: 3px;
   
  }
  .results-save-btn{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;

  }

  .results-save-btn .save-csv-icon{
    margin-left: auto;
   
    
  }
  .table-view-save-btns{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  

.results-table-content th,
.results-table-content td {
    white-space: nowrap; 
    text-align: left; 
    padding: 10px; 
    border: 0.1px solid #dedbdb; 
}

.results-table-content th {
    background-color: #1f4f8f; 
    color: white; 
  
}
.no-data{
    margin-top: 100px;
    font-size: 16px;
}
.appliedtag-section{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 5px;
    padding: 5px 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); 
    border: 0.5px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 5px;
    background-color: #fefefe;
}

.filter-name{
    font-size: 14px;
    margin-bottom: 10px;
}
.appliedtag-section .filter-tag{
  display: inline-block;
  padding: 3px 3px 3px 10px;
  background-color: #abbcdd;
  border: 1px solid #abbcdd;
  border-radius: 100px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
 
}
.appliedtag-section .filter-tag span{
font-size: 12px;
}
.appliedtag-section .filter-tag button{
    color: white;
}
.tags-display{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    
}


.add-filters-btn{
    margin-top: 10px;
    margin-left: auto;
    position: relative;
    z-index: 2; /* Higher z-index to keep button above sliding div */
   
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  
}
.add-filters-btn span {
    margin-right: 5px;
    font-size: 14px;
  
}
.add-filters-section{
    /* position: relative; */
    width: 100%; /* Adjust the width as necessary */
    overflow: hidden; 
  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
   
   
    
}
.filters-buttons-content{
    display: flex;
    flex-direction: row;
   align-items: center;
   justify-content: space-between;
    margin-top: auto;
    padding: 0 10px;
    margin-left: auto;
    /* background-color: aqua; */
    top: 12px;
    margin-right: 0;
    width: 75%;
    transition: all 0.3s ease-in-out;
}
.filters-buttons-content.active {
   transform: scale(0.1);
   margin-right: -100%;
   transition: all 0.3s ease-in-out;
}

.filters-buttons-content .child-filter-btn{
    position: relative; 
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #1f4f8f;
    color: #1f4f8f;
    position: relative;
    overflow: hidden;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.3s ease;
    margin: auto !important;
    z-index: 10;
   
}
.child-filter-btn.active {
    background-color: #1f4f8f; 
    color: #ffffff; 
}


.filters-buttons-content .child-filter-btn::after {
    content: '';
    position: absolute;
    top: 100%; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1f4f8f; 
    transition: top 0.2s ease; 
    z-index: -1;
   
}

/* Hover state styling */
.filters-buttons-content .child-filter-btn:hover::after {
    top: 0; 
}

.filters-buttons-content .child-filter-btn:hover {
    color: #fff; 
}

.filter-dropdown-content {
    display: block;      
    position: absolute; 
    background-color: #fefefe; 
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 10px; 
    width: 200px; 
    z-index: 1000 ;
    top: 100%; 
    left: auto; 
    margin-top: 5px; 
}
.filter-dropdown-content button{
  border: none;
  background-color: transparent;
  color: black;
}

.drop-input{
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
 
}
.filter-dropdown-content input{
    width: 90%; 
    margin: 10px 0 ;
    padding: 8px; 
    border: 1px solid #ddd; 
    border-radius: 4px; 
    box-sizing: border-box; 
    font-size: 10px;
}

/* Ensuring visibility of dropdowns */
.add-filters-section, .appliedFilters {
    position: relative; 
    z-index: 1000; 
    overflow: visible;
}
.filter-dropdown-content .filter-apply-button{
    background-color: #1f4f8f;
    color: #ffffff;
    width: 100%;
    margin-top: 10px ;
}
.filter-dropdown-content .filter-apply-button:hover{
    background-color:  #163f75;
}

.tableview-loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 200px;
}

.tableview-spinner {
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1f4f8f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); 
  border: 0.5px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 5px;
  background-color: #fefefe;
}
.filter-value-container{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
}
