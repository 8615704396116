.person-info-filter {
    margin: 20px 0px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;   
   
}

.person-info-filter h4 {
    margin-bottom: 10px;
    text-align: left;
}

.dropdown {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    text-align: left;
    color: black;
}

.dropdown-toggle .arrow {
    margin-left: auto;
    color: black;
    font-size: 10px;
}
.arrow {
    transition: transform 0.3s ease; /* Smooth transition for rotating the arrow */
}

.arrow.up {
    transform: rotate(180deg);
    color: #1f4f8f;
}


.dropdown-toggle:hover {
    background-color: #f0f0f0;
}

.dropdown-content {
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.dropdown-content input[type="text"] {
    width: calc(100% - 20px);
    /* Adjust for the width of the button */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
}

.filter-tag {
    display: inline-block;
    padding: 3px 3px 3px 10px;
    background-color: #abbcdd;
    border: 1px solid #abbcdd;
    border-radius: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 14px;
}


.filter-tag button {
  background-color: #1f4f8f;
  border: none;
  cursor: pointer;
  color: #fff;
  margin-left: 6px;
  padding: 2px 5px;
  border-radius: 50%;
}

.dropdown.active .dropdown-content {
    display: block;
}

.filter-search {
    background-color: #fff;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
 height: auto;
 
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.filter-buttons h3 {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
}

.filter-actions-btn {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: auto;
    margin-top: 10px;
}
.filters-reset-btn{
  margin-left: auto;
}

.filter-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 10px;
    gap: 30px;
}

.filter-count {
    display: inline-flex;
    align-items: center;
    padding: 2px 10px;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid #ccc;
    /* Light grey border */
    border-radius: 20px;
    /* Rounded corners for tag-like shape */
    margin-left: 20px;
    font-size: 14px;
    /* Smaller text size */
    color: #333;

}
.filter-count:hover {
    border: 1px solid #1f4f8f;
    color: #1f4f8f;
}
.filter-count:hover button {
   color: #1f4f8f; /* Change button background on hover */
}

.filter-count span {
    margin-right: 10px;
}

.filter-count button {
    border: none;
    background-color: transparent;
    color: black;
    border-radius: 10px;
    padding: 3px 2px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
}

.table-select-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
.table-select-label{
    color: #1f4f8f;
    margin-left: 0;
}
.table-select-wrapper{
    margin-left: 0px;
    position: relative;
}
.table-select-input {
    position: relative;
    display: inline-block;
   
  }
  
  .table-select-input #table-select {
    margin-left: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    color: #1f4f8f;
    cursor: pointer;
    border: 1px solid #ccc;
    width: 500px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

  }
  
  /* .table-select-input::after {
    content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: black;
  pointer-events: none;
  font-size: 10px;
  } */


/* The switch - the box around the slider */
.toggle-switch-search{
    margin-left: 10px;
  color: #1f4f8f;
  display: flex;
  align-items: center;
    
}
.switch {
    position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Off color */
    transition: .4s;
    border-radius: 34px; /* Circular borders */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Effectively smaller than the container height to make a round shape */
    width: 18px; /* Same as height */
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; /* Fully rounded */
  }
  
  input:checked + .slider {
    background-color: #1f4f8f; /* On color - Blue */
  }
  
  input:checked + .slider:before {
    transform: translateX(20px); /* Move the toggle to the right on check */
  }
  
  /* Optional: CSS for hover effect on the slider */
  .slider:hover {
    background-color: #bcb9b9; /* Lighter blue when hovering, only when off */
  }
  
  input:checked + .slider:hover {
    background-color: #1f4f8f; /* Slightly lighter blue when on and hovered */
  }
  
  .loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .table-select-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 100%;
    left: 10px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    min-width: 200px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* Add this line to match the width of the input */
    box-sizing: border-box; /* Add this line to include padding in the width */
  }
  .table-select-dropdown label:hover{
    background-color: #f0f0f0;
  }
  .table-select-dropdown .table-select-option.selected {
    background-color: #1f4f8f;
    color: white;
  }
  .table-select-dropdown .table-select-option p{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
  }
  
  .table-select-dropdown label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    margin-left: 0;
    font-size: 12px;
    border-radius: 5px;
    padding: 2px;
  }
  
  .table-select-dropdown input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
  }
  
 