.enrich-data-container {
    font-family: Arial, sans-serif;
    /* max-width: 800px; */
    margin-top: 10px;
    /* margin-right: 20px; */
    padding-bottom: 0;
    /* padding: 10px; */
    /* background-color: #fff; */
}

.header-tabs {
    background-color: #fff;
    border-radius: 5px;
    /* width: 100%; */
    text-align: left;
    padding: 10px;
    padding-bottom: 2px;
    margin-bottom: 20px;
}

.header-tabs button {
    background-color: #fff;
    padding: 10px 2px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    color: black;
    margin-bottom: 0;
    transition: all 0.3s ease;
}

.header-tabs button.active-tab {
    /* background-color: #ddd; */
    border-bottom: 2px solid #1f4f8f;
    color: #1f4f8f;
}


.header-tabs h2 {
    margin: 10px;
}

.file-button-section {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 20px;
}

.file-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.enrich-tabs {
    display: flex;
    gap: 50px;
    margin-bottom: 0;
    margin-left: 10px;


}

.selected-file-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;

}

.selected-file-section label {
    margin-top: auto;
    margin-bottom: auto;
}

.selected-file-section input {
    max-width: 400px;
    margin-top: auto;
    margin-bottom: auto;
}

.enrich-tabs button p {
    font-size: 18px;
    text-align: center;
    margin-top: 0;
    padding: 10px 0;

}

.enrich-filter-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;

}

.enrich-filter-section h3 {
    text-align: left;
    margin-left: 0;
    margin-top: 10px;
    margin-left: 5px;
}
/* .location-section{
    display: flex;
    flex-direction: column;
  
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    border: 1px solid #D6D9DF;
    background-color: #f4f5f7;
   
} */
.location-section input{
    width: calc(100% - 15px) !important;
    border: 1px solid #D6D9DF;
    border-radius: 5px;
}
.location-section label{
    text-align: left;
    font-size: 14px;
    margin-left: 2px;
}

.filter-section-container {
    display: flex;
    flex-direction: row;
   
    gap: 30px;
    margin-bottom: 0px;
    /* padding: 20px; */
    border-radius: 5px;
}

.filter-container-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;
}
.comapny-filter-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    text-align: left;
   
}
.companies-section,
.job-title-section {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    border: 1px solid #D6D9DF;
    background-color: #f4f5f7;
}

.job-title-section input {
    width: calc(100% - 15px) !important;
    margin-right: 20px;
    border: 1px solid #D6D9DF;
    border-radius: 5px;
}
.job-title-section label{
    text-align: left;
    margin-left: 2px;
    font-size: 14px;
}

.companies-section label {
    text-align: left;
    margin-left: 2px;
    font-size: 14px;
}

.companies-section input {
    width: calc(100% - 14px) !important;
    border: 1px solid #D6D9DF;
    border-radius: 5px;
}


.filter-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;

}

.filter-buttons button {
    background-color: transparent;
    color: black;
    border: 1px solid #D6D9DF;
    transition: all 0.3s ease;
}

.filter-buttons button:hover {
    border: 1px solid #1f4f8f;
    color: #1f4f8f;
    background-color: transparent;
}

.enrich-button {
    background-color: #1f4f8f;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.enrich-button.selected {
    color: #1f4f8f;
    border: 1px solid #1f4f8f;
}

.enrich-button:hover {
    background-color: #143e75;
}

.enrich-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

input[type="text"],
input[type="number"],
select {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
}

.enrich-data-error {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.enrich-data-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    width: 100%;
}
.enrich-data-table-table{
    margin: auto !important;
    height: 100%;
    overflow: auto;
}

.enrich-data-table th,
.enrich-data-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    max-width: 170px;
    min-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;

}

.enrich-data-table th {
    background-color: #1f4f8f;
    font-size: 14px;
    color: #fff;
}

.enrich-data-table tr:nth-child(even) {
    background-color: #f9f9f9;
}



.file-import-section {
    margin-bottom: 20px;
  }
  
  .file-tabs {
    display: flex;
    gap: 20px;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 10px 10px 0 10px;
  }
  
  .file-tab {
    padding: 10px 20px;
    background-color: #f1f1f1;
    color: #000;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px 5px 0 0;
  }
  .file-tab:hover{
    background-color:#f1f1f1;
    color: #000;
  }
  .file-tab.active {
    background-color: #1f4f8f;
    color: white;
  }
  
  .file-content {
    /* border: 1px solid #ddd; */
    display: flex;
    align-items: flex-start !important;
    border-radius: 4px;
    width: calc(100% - 20px);
    height: auto;
    margin-top: 0;
  }
  
  .import-csv {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    border: 2px solid transparent;

  }
  
  .import-csv label {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    font-size: 16px;
  }
  
  .import-csv input {
    max-width: 300px;
    flex-grow: 1;
    padding: 5px;
  }
  
  .drag-drop-area {
    border: 2px dashed #ccc;
    margin: 0;
    color: #afadad;
    border-radius: 5px;
    padding: 10px 30px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .drag-drop-area  p{
    margin:auto;

  }
  .drag-drop-area.drag-active {
    background-color: #f0f0f0;
    border-color: #4CAF50;
  }
  
  .selected-file {
    margin-top: 10px;
    font-weight: bold;
  }
  .selected-file{
    margin: 10px;
    font-size: 16px;
    text-align: left;
    color: #1f4f8f;
    font-weight: 500;
  }

  .enrich-data-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center
  }
  /* .enrich-data-table-container{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    width: 95%;
    height:85%;
    margin-top: 80px;
    border-radius: 5px;
  } */

  .enrich-data-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .enrich-data-table-container h3{
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  }
  .close-overlay{
    position: absolute;
    right: 5px;
    top:5px;
    font-size: 20px;
    padding: 5px ;
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: #000;
    text-align: center;
  }
  .close-overlay:hover{
    background-color: #ccc;
   
  }

  /* ... (previous CSS remains the same) */

.enrich-data-footer {
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    /* background-color: #c62828; */
    width: calc(100% - 40px);
  }
  
  .pagination button {
    background-color: #1f4f8f;
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
  }
  
  .download-csv {
    background-color: #1f4f8f;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .download-csv:hover {
    background-color: #1f3f8f;
  }
  .result-data-heading{
    width: calc(100% - 40px);
    border-bottom: 1px solid #ddd;
    padding: 0 10px 0 10px;
    margin-left: 10px;
    margin-right: 10px;

  }
  .result-data-heading h3{
    text-align: left;
    width: 100%;
  }
  .user-icon{
    margin-right: 5px;
 
  }
  .enrich-data-checkbox{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }
 
 .options-dropdown-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  width:calc(100% - 10px);
  /* background-color: aqua; */
  padding: 5px;
}

.option-btn-enrich-data {
  background-color: #1f4f8f;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: auto !important;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  transition: background-color 0.3s;
}

.option-btn-enrich-data:hover {
  background-color: #1f3f8f;
}

.options-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 10px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  padding: 5px;
}

.options-dropdown-menu button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options-dropdown-menu button:hover {
  background-color: #ddd;
}
.enrich-table-download-buttons{
  display: flex;
  gap: 10px;
 flex-direction: row;

}
.enrich-data-checkbox-all{
  margin-right: 10px;
}
.enrich-data-table-table .selected-row {
  background-color: #e6f7ff !important; 
}

/* Existing styles... */

.source-dropdown {
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  
}

.source-dropdown label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  width: 100px;
}

.source-dropdown select {
  padding: 4px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 200px;
}

.source-dropdown select:hover {
  border-color: #888;
}

.source-dropdown select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.source-dropdown select option {
  padding: 8px;
}

/* Rest of your existing styles... */


/* .enrich-data-table-table .enriched-row , .enriched-row.selected-row{
  background-color:  #c3e6cb !important; 
  transition: background-color 0.3s ease;
} */



.updated-field {
  color: green;
  font-weight: bold;
}

.title-input-container {
  position: relative;
}

.title-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
}

.title-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.title-dropdown li:hover {
  background-color: #f0f0f0;
}

.enrich-data-results {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.enriched-field {
  color: green;
  font-weight: bold;
}