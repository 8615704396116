.rdsTables {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #fff;


}

.RDS-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-left: auto;
}

.RDS-icon-container i {
  margin-left: 10px;
  cursor: pointer;
  background: none;
  border: none;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-left-color: #1f4f8f;
  animation: spin 1s ease infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}





.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.cancel-btn {
  position: fixed;
  top: 30px;
  right: 30px;
  height: 20px;
  width: 20px;
  padding: 10px;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  font-size: 22px;
  z-index: 4;
  color: white;
  background-color: rgb(37, 35, 35);
  transition: 0.2s;
}

.cancel-btn:hover {
  background-color: rgb(210, 8, 8);
}


.success-message {
  animation: slideInRightToLeft 0.5s ease-out forwards;
  padding: 20px;
  background-color: #4CAF50;
  color: white;
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 600;
  top: 50px;
  right: 30px;
  position: fixed;
  z-index: 99999;
}

@keyframes slideInRightToLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.rdsfiles {
  margin-top: 10px;

}

.rdsfiles .spinner {
  margin-bottom: 20px;
}

.table-list {
  padding: 0;
}

.rdsfiles .table-header {
  display: grid;
  grid-template-columns:65% 20% 15%;
  padding: 10px 10px;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  background-color: #f8f9fb;
  border-bottom: 2px solid #eaeaea;
  border-radius: 10px;
}

.rdsfiles .table-header .sort-icon{
margin-left: 5px;
}
.rdsfiles .table-header span{
  font-size: 14px;
}

.rdstable-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.rdsfiles .table-header>span:first-child {
  flex-grow: 5.5;
  /* Takes up twice as much space as the others */
}

.rdsfiles .table-header>span:not(:first-child) {
  flex-grow: 1;

}

.rdstable-item {
  display: grid;
  grid-template-columns: 65% 20% 15%; 
  align-items: flex-start;
  padding: 1px 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  

}
.tablename-icon{
  display: flex;

  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
.tablename-icon:hover .table-icon{
color: #1f4f8f;
}
.tablename-icon:hover .rdstablename{
  color: #1f4f8f;
  
}
.rdstable-item button {
  padding: 5px;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  transition: 0.3s;
}

.rdstable-item button:hover {
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  color: #1f4f8f;
}

.rdsfiles .table-header span {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.rdstable-item span {
  text-align: left;
  padding: 10px 10px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  font-size: 14px;

}



#tooltip-view,
#tooltip-create-table,
#tooltip-invite,
#tooltip-delete,
#tooltip-clipboard,
#tooltip-save,
#tooltip-api-info {
  background-color: #1f4f8f;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  z-index: 99999;

}

.table-actions-icon-rds {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
  gap: 5px;
  margin-right: 30px;
  width: 100%;
  overflow: auto;
}

.rdstablename {
  color: black;
  font-size: 12px !important;
  width: 100%;
 
 
}
.rdstablename:hover{
  color: #1f4f8f;
  text-decoration: underline;
}
.rdstablename:hover .rdstable-item .table-icon{
  color: #1f4f8f !important;
}

.rdstable-item .table-icon {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.tables-overlay-content h3 {
  margin-top: 10px;

  text-align: left;
}

.execute-query-btn {
  margin-top: 10px;
}

.sqlquerySelect {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 20px;

}

.sqlquerySelect label {
  text-align: left;
  margin: 0;

}


.rdslabel {
  font-size: 25px;
  margin: 20px 0;
  font-weight: 500;
}

.error-message {
  animation: slideInRightToLeft 0.5s ease-out forwards;
  padding: 20px;
  background-color: #f44336;
  color: white;
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 600;
  top: 50px;
  right: 30px;
  position: fixed;
  z-index: 99999;
}

.overlayNotes-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayNotes-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Box shadow for a subtle effect */
  max-width: 400px;
  /* Adjust width as needed */
  width: 100%;
}

.overlayNotes-header {
  margin-bottom: 10px;
}

.overlayNotes-header h3 {
  margin: 0;
  font-size: 1.5rem;
  text-align: left;
}

hr {
  margin: 10px 0;

}

.notes-section textarea {
  width: 380px;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  /* Disable resizing */
  overflow-y: auto;
}

.timestamp {
  margin-top: 10px;
  font-style: italic;
  color: #666;
}

.button-section {
  margin-top: 20px;
  text-align: right;
}

.button-section button {
  margin-left: 10px;
}

.button-section .addnotes {
  background-color: #1f4f8f;
  color: white;
  padding: 10px;
}

.button-section .notescancel {
  background-color: #f44336;
  color: white;
  padding: 10px;
}

.button-section .addnotes:hover {
  background-color: #133e76;
  color: white;
}

.button-section .notescancel:hover {
  background-color: #d32d21;
  color: white;
}

.sql-query-dropdown {
  font-size: 14px;
  padding: 3px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  background-color: #1f4f8f;
  width: 100%;
  max-width: 300px;
  margin-left: 5px;
  color: white;
}


.saved-notes {
  position: relative;
  margin-top: 20px;
  width: 500px;
  /* Adjust the width as needed */
  height: 100px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Add scrollbar if content exceeds height */
  background-color: white;
  margin-left: 0;
  margin-top: 0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: block;
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.saved-notes.hide {
  transform: scale(0);
}

.saved-notes.show {
  transform: scale(1);
}

.saved-notes-label {
  position: sticky;
  top: 0;
  margin-left: 0;
  margin-top: 0;
  font-size: 15px;
  padding: 5px 5px 0 5px;
  color: #1f4f8f;
  background-color: white;
  /* Add a background color if needed */
  z-index: 1;
}

.textarea-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.label-notes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: auto;
  width: 500px;
  height: 100px;

}


.note-item {
  line-height: 0.5;
  /* Adjust this value as needed to reduce the line spacing */
  padding: 5px;
  margin-top: 0;
}


.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #f44336;
  font-size: 18px;
  z-index: 9999;
}

.search-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.save-csv-icon {
  font-size: 26px;
  color: #1f4f8f;
  margin-top: 10px;
}
/* Styles for the tag tile */
.info-form-group .tag-tile-rds {
  display: inline-flex;
  align-items: center;
  padding: 5px 8px !important;
  margin-right: 10px;
  margin-bottom: 10px !important;
  color: white;
  background-color: #1f4f8f;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}

/* Styles for the remove button inside the tag tile */
.info-form-group .tag-remove-btn-rds{
  margin-left: 5px;
  cursor: pointer;
}

.tables-container .info-form-group.editing-mode .tag-tile-rds {
  padding-top: 0 !important;
  padding-bottom:0 !important;
}
.tables-container .info-form-group.editing-mode .tag-tile-rds span{
  padding: 5px 2px;
}
.tableadvanced-search{
  background-color: aqua;
  width: 200px !important;
  margin-right: auto !important;
}
.table-advanced-search-btn{
  margin-right: auto !important;
  margin-top: 6px !important;
 
}
 .table-search-bar{
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  width: auto;
}
#searchInput{
  width: 200px;
}
.table-list .table-date{
  font-size: 12px;
}
