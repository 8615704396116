.locker-container {
    font-family: Arial, sans-serif;
    background-color: #fff;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
   
  }
  
  .locker-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;

  }
  .locker-header button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    padding: auto;
  }
  .locker-header-toggle-button {
    position: relative;
    background-color: transparent;
    color: #1f4f8f;
    border: 1px solid #1f4f8f;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
   font-size: 16px;
    margin:4px 0;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden; 
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  .locker-header-toggle-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%; /* Start with no fill */
    background-color: #1f4f8f; /* Background color to fill on hover */
    transition: height 0.3s ease; /* Animation effect from bottom to top */
    z-index: -1; /* Ensures the text stays on top */
  }
  .locker-header-toggle-button:hover::after {
    height: 100%; /* Full fill on hover */
  }
  .locker-header-toggle-button:hover{
    color:white;
  }
  .locker-header-toggle-button span {
    margin-right: 10px;
    font-size: 18px;
  }

  
  .locker-header {
    border-collapse: collapse;
    width: 100%;
    border-bottom: 1px solid #ccc ;
    padding: 10px 0
  }
  .locker-header p{
    font-size: 20px;
  }

  .locker-list{
    margin-top: 30px;
  }
 
  
  .locker-item {
    display: grid;
    grid-template-columns: 35% 15% 20% 15% 15%;
    align-items: center;
    padding: 0 5px;
    border-radius: 10px;
    font-size: 12px;
   
  }
  .locker-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  .locker-item-header{
    grid-template-columns: 35% 15% 20% 15% 15%;
    font-size: 14px;
    padding: 2px 5px;
    background-color: #f8f9fb ;
    border-bottom: 2px solid #eaeaea;
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .locker-item-header:hover{
    box-shadow: none;
  }
  .locker-item-header:hover .locker-name{
    color: black;
    text-decoration: none;
    cursor: default;
  }
  .locker-name {
    display: flex;
    align-items: center;
    gap: 10px; 
    cursor: pointer;
    position: relative;
   
    width: auto;
  }
  .locker-name-text {
    display: inline-block;
    max-width: calc(100% - 20px); /* Adjust the width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
/*   
  .locker-name:hover .locker-name-text::after {
    content: attr(title);
    position: absolute;
    top: 20px; 
    left: 0;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1;
  } */
  .locker-name:hover{
    color: #1f4f8f;
    text-decoration: underline;
  }
  .locker-reason, .locker-owner, .locker-location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .locker-options {
    text-align: left;
    cursor: pointer;
   
    
  }
  
  /* You might need additional CSS for icons, hover states, and responsive design */

  .locker-item-header > div, .locker-item > div {
    padding: 8px; 
  }
  .icon-folder-lock{
   font-size: 20px;
   margin-left: 0;
  }
.font-icon-container{
  display: flex;
  align-items: flex-start;
  width: 20px;
 
  padding: 2px 2px;

}

#tooltip-top-more-actions{
    background-color: #1f4f8f;
    color: white;
    border-radius: 5px;
    padding: 2px 5px;
    z-index: 10000;
}
.action-btn-lockers{
    height: 30px;
    width: 20px;
    padding: 0;
    background-color: transparent;
    color: black;
    margin: 0 0 0 0 ;
}
.action-btn-lockers:hover{
    background-color: transparent;
}
#tooltip-save{
  
    font-size: 14px;
}
.ellipsish{
    /* background-color: aqua; */
    width: 15px;
    margin-left: auto;
    font-size: 14px;
 
}
.sort-icon{
  cursor: pointer;
}
.sort-name-icon{
  margin-left: -5px;
}

.create-new .create-dropdown p{
  margin-top: 0;
  
}
.create-new{
/* background-color: bisque !important; */
margin-top: auto;
margin-bottom: auto;
}
.locker-container.dragging {
  border: 2px dashed #1f4f8f;
}
.lockers-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-left-color: #1f4f8f;
  animation: spin 1s ease infinite;
  margin: auto;
}
.upload-component{
  margin-left: auto;
 margin-bottom: 10px;
 height:auto;
  /* background-color: aqua; */
  
}
.locker-header-text{
  /* background-color: aqua; */
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 1000px) {
  .locker-owner,
  .locker-location {
      display: none;
  }
  .locker-item{
    grid-template-columns: 50% 25% 25% ;
  }

}
@media screen and (max-width: 1000px) {
  .locker-item{
    grid-template-columns: 40% 30% 30% ;
  }
}
@media screen and (max-width: 600px) {
  .locker-item{
    grid-template-columns: 50% 30% 20% ;
  }
  .locker-options{
    text-align: right;
  }
}