.dropdown-sidebar {
    position: fixed;
    top: 75px;
    left: -270px;
    width: 270px;
    height: calc(100vh - 60px);
    background-color: #fff;
    transition: left 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .dropdown-sidebar.collapsed {
    left: 0;
  }
  
  .dropdown-sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
  }
  
  .dropdown-sidebar-menu li {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 3px;
    margin-top: 5px;
  }
  
  .dropdown-sidebar-menu li:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-sidebar-menu li.active {
    background-color: #5f63f20d;
    color: #1f4f8f ;
    border-right: 2px solid #1f4f8f ;
  }
  .dropdown-sidebar-menu li.active .font-icon{
    color: #1f4f8f ;
  }
  
  .dropdown-sidebar-menu li .font-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .dropdown-sidebar-menu li .head-label-text {
    flex-grow: 1;
  }
  
  .dropdown-sidebar-menu .submenu {
    display: none;
    padding-left: 20px;
  }
  
  .dropdown-sidebar-menu .submenu.expanded {
    display: block;
  }
  
  .dropdown-sidebar-menu .submenu li {
    padding: 8px 20px;
  }
  
  .dropdown-sidebar-menu .locker-submenu {
    padding-left: 50px;
  }
  
  .dropdown-sidebar-menu .locker-submenu.expanded {
    display: block;
  }
  
  .dropdown-sidebar-menu .locker-item {
    padding: 8px 20px;
  }
  
  .dropdown-sidebar-menu .locker-item.active {
    background-color: #e0e0e0;
  }
  
  .dropdown-sidebar-menu .locker-name-section {
    display: flex;
    align-items: center;
  }
  
  .dropdown-sidebar-menu .locker-name-section .font-icon {
    margin-right: 5px;
  }
  
  .dropdown-sidebar-menu .sidebar-locker-head {
    flex-grow: 1;
  }
  
  .dropdown-sidebar-menu .sidebar-locker-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media screen and (min-width: 801px) {
    .dropdown-sidebar {
      display: none;
    }
  }