.step-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 600;
   
    
  }
  .findDuplicateApp{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-bottom: 20px;
    border-radius: 5px;
    margin-top: 50px;
    max-height: 600px;
  }
  
  .stepBtn{
    display: flex;
    align-items: center;
    justify-content: center; /* Center button and text horizontally */
    flex-direction: row;
    padding: 20px;
    height: 20px;
    width: auto;
    margin: 20px 0;
    border-radius: 5px;
  }
  .step-navigation hr {
    border: none;
    height: 1px;
    width: 140px;
    background-color: #333; /* Dark grey line */
    margin: 20px 0; /* Add vertical space around the <hr> */
  }
 
  
  .stepBtn:hover{
    background-color: #eae5e5;
  }
  
  .step-button {
    background-color: #c5c1c1;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    margin-right: 5px;
   
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .step-button.active {
    background-color: #1f4f8f;
    color: white;
  }
  
  .step-button:disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  .step-button:not(:disabled):hover {
    background-color: #1f4f8f;
   
    color: white;
  }
  
  .step-line {
    background-color: #cccccc;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 45px; 
    right: 45px; 
    z-index: -1;
  }
  .selectOptions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 40px;
    gap: 30px;
  }
  label{
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
  }
 
  .selectOptions input{
    height: 15px;
    width: 15px;
  }
  .file-management{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    margin-left: 10px;
    
  }
  .file-actions{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 20px;
  }

  .file-display-area{
    margin-bottom: 30px;
    
  }
  .navigation-buttons{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 30px;
  }
#backbtn{
    background-color: #eae5e5;
    color: black;
}
#backbtn:hover{
    background-color: #d2cdcd;
}
.cancel-file {
    margin-left: 20px;
    color: red;
    cursor: pointer;
    font-size: 16px; 
   text-align: center;
  }
  .file-display-area li{
    border: 1px solid  #1f4f8f;
    padding: 5px 10px;
    border-radius: 5px;
  }
 .processingStep{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 30px;
    margin-left: 10px;
 }
 .processcingBtns{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 30px;
 }
 .stepsCompleted{
    display: flex;
    flex-direction: column;
 }
 .stepsCompleted p{
    text-align: left;
    font-weight: 500;
    font-size: 18px;
 }
 .icon-check {
    color: white;
    margin-left: 15px;
    background-color: green;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 3px;
  }
  
  .icon-times {
    color: white;
    margin-left: 10px;
    background-color: red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 3px;
  }