.shared-locker-component {
    width: 100%;
  }
  
  .shared-locker-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 10px;
    justify-content: space-between;
    padding: 10px 20px 2px 20px;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .shared-locker-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .shared-locker-header .fa-lock {
    margin-right: 5px;
  }
  
  .shared-locker-tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap:20px;
  }
  .shared-locker-tabs li{
    padding: 10px 1px !important;
    width: auto;
    
    
  }
  
  .shared-locker-tabs .shared-locker-tab {
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .shared-locker-tabs .shared-locker-tab.active {
    background-color: transparent;
    border-bottom: 2px solid #1f4f8f;
    color: #1f4f8f;
  }
  
  .shared-locker-content {
    margin-top: 20px;
  }
  .shared-lockerItems-table{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 20px);
  }