.login-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
}



.login-card {
  background-color: #ffffff;
  width: 300px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.login-heading {
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  font-size: 1.75rem;
}

.login-container-div .login-inputs {
  margin-top: 30px;
  
}

.input-container-login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  /* background-color: aqua; */
}

.input-container-login label {
  text-align: left;
  margin: 0;
  font-weight: 400;
}

label span {
  color: #9ca6af;
  font-size: 15px;
  font-weight: 400;
}
.input-with-icon {
  position: relative;
  width: 100%;
}

.input-container-login input {
  width: 100%;
  padding: 10px;
  /* padding-right: 40px;  */
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 10px;
  height: 30px;
  box-sizing: border-box;
  font-size: 14px;
}

.eye-icon {
  position: absolute;
  /* margin: auto; */
  font-size: 15px;
  right: 10px;
  top: 40%;
  color: grey;
  border: none;
  cursor: pointer;
  z-index: 1000;

}




.login-button,
.other-login-button {
  width: calc(100% - 6px);
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #1f4f8f;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.other-login-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 20px;
}

.google,
.windows,
.slack {
  background-color: white;
  color: rgba(0, 0, 0, .54);
  font-size: 16px;
}

.forgot-password {
  display: block;
  text-align: center;
  margin-bottom: 15px;
  text-decoration: none;
  color: #9ca6af;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.sign-up {
  text-decoration: none;
  color: #1f4f8f;
  font-weight: bold;
  transition: width 0.3s ease;
}

.sign-up::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  top: 79.5%;
  background-color: #1f4f8f;
  transition: 0.1s;
  border-radius: 1000000px;
}

.sign-up:hover::before {
  width: 48px;
}

.dont {
  text-align: center !important;
  margin-top: 20px;
}

p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 1px;
}

.btn-logo {
  height: 18px;
  width: 18px;
  margin-left: 20%;
  margin-right: 3%;
}

h1 {
  margin-top: 20%;
  font-size: 40px;
  text-align: center;
}

h3 {
  margin-top: -10px;
  width: 230px;
  text-align: center;
  font-size: 20px;
}

.login-spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border-left-color: #1f4f8f;
  animation: spin 0.8s ease infinite;
  margin: auto;
}

/* Responsive Design Adjustments */
@media screen and (max-width: 768px) {
  .login-container {
    margin-right: 10px;
    margin-left: 10px;
    align-items: stretch;
  }

  .login-card {
    width: auto;
    margin: 0 20px;
  }

  /* .input-container, .login-button, .other-login-button {
      width: auto;
    } */

  h1 {
    font-size: 32px;
  }

  h3 {
    width: auto;
    font-size: 18px;
  }
}


@media screen and (max-width: 900px) {
  .login-card {
    background-color: #ffffff;
    width: 250px;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;

  }

  h1 {
    font-size: 32px;
    margin-top: 25%;
  }

}

@media screen and (max-width: 700px) {
  .login-card {
    width: 250px;
    margin: auto;
    margin-right: 20px;
    margin-top: 20px;

  }
}

@media screen and (max-width: 650px) {
  .login-card {
    width: 300px;
    margin: auto;

  }

  h1 {
    font-size: 32px;
    width: 300px;
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  h1 {
    margin-top: 20%;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    margin-top: 25%;
  }

  .login-card {
    margin-top: 30px;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    margin-top: 25%;
  }

  .login-card {
    margin-top: 30px;
  }
}