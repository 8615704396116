.linkslabel {
    font-size: 25px;
    margin: 20px 0;
   text-align: left;
   font-weight: bold;
  }
 
  .my-links{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
   margin-top: 0;
    border-radius: 5px;
    background-color: #fff;
  }
  .link-lable-button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .link-lable-button button{
    margin-left: auto;
  }


  .link-lable-button .btn-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    padding: auto;
  }
  .link-lable-button button {
    position: relative;
    background-color: transparent;
    color: #1f4f8f;
    border: 1px solid #1f4f8f;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
   font-size: 16px;
    margin:4px 0;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden; 
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  .link-lable-button button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%; /* Start with no fill */
    background-color: #1f4f8f; /* Background color to fill on hover */
    transition: height 0.3s ease; /* Animation effect from bottom to top */
    z-index: -1; /* Ensures the text stays on top */
  }
  .link-lable-button button:hover::after {
    height: 100%; /* Full fill on hover */
  }
  .link-lable-button button:hover{
    color:white;
  }
  .link-lable-button button span {
    margin-right: 10px;
    font-size: 18px;
  }
  .link-label-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.rdslabel {
    margin: 0;
}

.btn-div {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-div span {
    margin-right: 5px;
}


.tablename-icon a{
  text-decoration: none;
}
.my-links .info-form-group.editing-mode .tag-tile {
  padding-top: 0 !important;
  padding-bottom:0 !important;
}
.my-links .info-form-group.editing-mode .tag-tile span{
  padding: 5px 2px;
}
.table-list .link-date{
  font-size: 12px;
}