.all-actions-icon {

  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: auto;
}

.all-actions-icon button {
  padding: 5px;
  background: transparent;
  color: black;
}

.all-actions-icon button:hover {
  color: #1f4f8f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.all-actions-icon .fileInfo-overlay-content p {
  font-size: 14px;
}

.all-actions-icon .editname {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.all-actions-icon .filename-text {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.all-actions-icon .filename-editable-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px 5px;


}

.locker-name-dropdown {
  font-size: 16px !important;
  color: #1f4f8f;
  /* background-color:antiquewhite; */
  padding: 0 !important;
  margin-top: auto;
  margin-bottom: auto;

}

.label-locker-name {
  margin-left: 0;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
}

.locker-lable-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  /* background-color: aqua; */
  height: 20px;
}

.locker-dropdown {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px 5px;
}

.password-label-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  padding: 5px 0;


}

.label-file-name {
  margin-left: 0;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
}

.label-password {
  margin-left: 0;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
}

.password-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px 5px;
  color: #1f4f8f;
}

.editmode-icon-share-edit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: auto;
  margin-right: 0;
  margin-top: auto;
  margin-bottom: auto
}

#tooltip-share-icon,
#tooltip-top-edit-icon {
  background-color: #1f4f8f;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  z-index: 10000000000;
}

.view-info-details-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  /* background-color: aqua; */

}

.view-info-details-content label {
  margin-left: 0;
  font-size: 16px;

}

.view-info-details-content .filename-text {
  margin-top: auto;
  margin-left: 5px;
}

.all-actions-icon {
  display: flex;
}

.actions-icon-full {

  display: flex;
}

.actions-icon-ellipsis {
  display: none;

}

.actions-dropdown {
  position: absolute;
  right: 70px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
 
  z-index: 1000;
}



@media (max-width: 600px) {
  .actions-icon-full {
    display: none;
  }

  .actions-icon-ellipsis {
    display: block;
    padding: 0;
  }

  .actions-icon-ellipsis {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* background-color: aqua; */
    margin-left: auto;
    padding: 0;
  }

  .actions-dropdown button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  

  }

  .actions-dropdown button:hover {
   box-shadow: none;
  }
  .actions-dropdown-icon-button{
    width: calc( 100% - 20px);
    padding: 2px 10px;
  }
  .actions-dropdown-icon-button:hover{
    background-color: #ccc;
  }
  .actions-dropdown-icon-button:hover  button{
    color: #1f4f8f;
  }

  .dropdown-menu-icon {
    height: 15px;
    width: 15px;
    margin: auto;
    margin-left: 0;
    padding: 3px;
  }

  .actions-dropdown button p {
    font-size: 12px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
   
  }
  .fileInfo-overlay{
    display: block;
  }
}