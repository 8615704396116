/* NavBar.css */

.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
   height: 74px;
    justify-content: space-between;
    align-items: center;
   
    background-color: #1f4f8f;
    padding: 0 20px; 
    z-index: 99999999999 !important;
}
.navbar .logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 26px;
    margin-left: 4px ;
}
  
  .logo img {
    max-height: 40px; 
  }
  

  /* navbar.css */
.profile-section {
  display: flex;
  align-items: center;
  position: relative;
  float: right;
  margin-right: 40px;
 
}
.profile-section span{
  color: white;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-section .dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white !important;
 
}
.profile-section .dropdown-button :hover{
    background-color: transparent !important;
}

.dropdown-menu {
  position: absolute;
  top: 150%;
  right: 10px;
  background-color: #343a40;
  border: 1px solid#343a40;
  border-radius: 5px;
  z-index: 10;
  color: white;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  width : 100px;
  height: 30px;
}
.logout-button:hover {
  background-color: #16181a;
}

@media screen and (max-width: 550px){
  .logo img {
    max-height: 30px; 
  }
}
@media screen and (max-width: 400px){
  .logo img {
    max-height: 25px; 
  }
}

.hamburger-menu {
   margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    width: 20px;
    height: 18px;
    cursor: pointer;
  }
  
  .line1 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 60%;
  }
  .line2 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 90%;
  }
  .line3 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 75%;
  }

  @media screen and (max-width: 600px) {
    .profile-section span{
      display: none;
    }
    .profile-pic{
      margin-right: 0;
    }
    .profile-section .dropdown-button {
     margin-left: 0;
     padding: 10px;
    }
  }



  .navbar-dropdown-menu {
    position: absolute;
    top: 90%;
    right: 0;
    margin-top: 0.5rem;
    width: 15rem;
    background-color: #ffffff;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    z-index: 10;
    
    /* Animation properties */
    transform-origin: top right;
    transition: all 0.2s ease-out;
    transform: scale(0.1);
    opacity: 0;
  }
  
  /* Class to trigger the animation */
  .navbar-dropdown-menu.active {
    transform: scale(1);
    opacity: 1;
  }
  

.navbar-dropdown-content {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #1f4f8f;
  opacity: 0.5;
  text-decoration: none;
  transition: background-color 0.2s;
  border-radius: 5px;
}

.navbar-dropdown-item:hover {
  background-color: #f7fafc;
  opacity: 1;
  border-radius: 5px;
}

.navbar-dropdown-icon {
  margin-right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  color: #1f4f8f;

}

.navbar-dropdown-signout {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.875rem;
  color: #4a5568;
  opacity: 1;
  background-color: #f7fafc;
}
.profile-info{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;


}


