.locker-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.locker-search-input {
    padding: 9.5px;
    border-radius: 5px;
    width: 200px;
    border: 0.5px solid;
    margin-left: 10px;
    margin-top: 5px;
}

.locker-search label {
    margin-top: auto;
    margin-bottom: auto;
    color: #1f4f8f;
}

.locker-search-dropdown {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   /* background-color: aqua; */
    width: 100%;
 
 
}

.locker-search-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: auto;
}



/* .locker-search-results {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: aqua;
    width: 100%;
} */
.locker-search-results{
    width: calc(100% - 40px);
    background-color: #fff;
   margin-top: 20px;
   padding: 20px;
   border-radius: 5px;
 
}


.locker-search-item{
   display: grid;
   grid-template-columns: 85% 15%;
    margin-top: 0;
}

.locker-search-results .locker-search-item-icon  {
  width: 30px;
  font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0;
  
}
.locker-search-result-details{
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  width: 100%;
}
.locker-search-item-container{
    margin-bottom: auto !important;
    /* background-color: aqua; */
}
.locker-search-item {
    font-size: 12px;
    /* background-color: aqua; */
    width: 100%;
   
}
.locker-search-last-updated{
  font-size: 12px;
   
    width: 100%;
   margin-left: 0;
}
.locker-search-results .locker-search-results-header{
    display: grid;
    grid-template-columns: 86.5% 13.5%;
   
}
.locker-search-result-date{

   margin-left: 10px !important;
    
}
.locker-search-tabs {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
   
  }
  
  .locker-search-tab {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .locker-search-tab.active {
    background-color: #1f4f8f;
    color: white;
  }
#locker-select{
    width: 200px;
    position: relative;
    margin-top: 10px;
   padding: 5px;
   padding-left: 5px;
   border-radius: 5px;
   border: 1px solid #ccc;
   transition: all 0.3s ease-in-out;
   color: #1f4f8f;
}
.select-locker-label{
    margin-right: 10px;
    margin-top: 15px !important;
    margin-left: 0;

}
.locker-search-bar{
    margin-left: auto;
    margin-top: 0;
}
 .input-container .locker-search-input-bar{
   width: 270px !important;
}


  .locker-select-dropdown-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
    box-sizing: border-box; /* Add this line to include padding in the width */
  }
  .locker-select-dropdown-content label{
    font-size: 14px;
    color: black;
  }
  .locker-select-dropdown-content input{
    margin-right: 10px;
   
  }
  .locker-select-dropdown-content label:hover{
    background-color: #f0f0f0;
  }
  .locker-select-dropdown-content .locker-select-option.selected {
    background-color: #1f4f8f;
    color: white;
  }
  
  
  .locker-select-dropdown-content label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    margin-left: 0;
    font-size: 12px;
    border-radius: 5px;
    padding: 2px;
  }
  
  .locker-select-dropdown-content input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
  }
  .locker-select-container{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;    
    margin-top: auto;
    margin-bottom: auto;
    
  }
  .search-bar.locker-search-bar.show {
    display: block;
  }
  
  .search-bar.locker-search-bar.hide {
    display: none;
  }
  .table-filters{
    width: 100%;
    
    /* background-color: aqua; */
  }


@media screen and (max-width: 600px) {
  .locker-search-tab {
     font-size: 14px;
  }
  .select-locker-label{
    font-size: 14px;
  }
}