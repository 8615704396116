.s3bucket{
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
}
.files-card{
    background-color: #fff;
   
}
.file-item {
    padding: 5px;
    margin-bottom: 5px;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    cursor:default;
}

.file-item input {
    padding: 0;
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.file-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


#fileList {
    margin-top: 10px;

}
#fileList .file-header {
    display: grid;
    grid-template-columns: 38% 15% 15% 15% 17%;
    align-items: center;
    padding: 10px 5px;
    margin-bottom: 5px;
    background-color: #f8f9fb;
    border-bottom: 2px solid #eaeaea;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

#fileList .file-header span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
}
#fileList .file-item {
    display: grid;
    grid-template-columns: 38% 15% 15% 15% 17%;
    padding: 5px 5px;
    margin-bottom: 5px;
  

    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}


.file-actions-icon {
    display: flex;
    gap: 5px;
    margin-right: 10px;
    overflow: auto;
   
    width: 100%;

}

.spanlabel {
    text-align: left;
    /* background-color: #c33535; */
    margin-left: 1px;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


}
.file-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
#fileList .file-item .fileName {
    cursor: pointer;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    text-align: left !important;
   

}


.file-item .fileName:hover {
    text-decoration: underline;
    
}

.file-item.selected:hover .fileName{
    background-color: #1f4f8f;
    color: white;
}
.file-item.selected:hover .icon-folder-lock{
    background-color: #1f4f8f;
    color: white;
}
.file-item.selected {
    background-color: #1f4f8f;
    color: white;
}

.file-item.selected span {
    color: white;
}


.file-item.selected button {
    color: white;
}

.file-item.selected button:hover {
    color: #1f4f8f;
}

.inputName {
    display: flex;
    margin: auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.inputName input{
    margin-right: 15px;
}
.inputName:hover .fileName{
color: #1f4f8f;
/* text-decoration: underline; */
}
.inputName:hover .icon-folder-lock{
    color: #1f4f8f;
}

#tooltip-top-view,
#tooltip-top-create-table,
#tooltip-top-invite,
#tooltip-top-delete,
#tooltip-top-search,
#tooltip-top-add,
#tooltip-top-refresh,
#tooltip-top-send,
#tooltip-top-info,
#tooltip-top-edit ,
#tooltip-share-icon,
#tooltip-top-more,
#tooltip-top-edit-icon {
    background-color: #1f4f8f;
    color: white;
    border-radius: 5px;
    padding: 2px 5px;
    z-index: 10000;
}

#notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.notification {
    padding: 10px 20px;
    margin-bottom: 10px;
    color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.5s ease forwards;
}

.notification.success {
    background-color: #4CAF50;
    /* Green */
}

.notification.error {
    background-color: #F44336;
    /* Red */
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    /* Ensure it's above most things */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapping-checkbox-interface {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mapping-checkbox-interface .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 0;
}

.mapping-checkbox-interface h2 {
    padding: 0;
    margin: 0;

}

.mapping-checkbox-interface button {
    margin-left: auto;

}

.checkbox input {
    padding: 0;
    margin: 0;
    border-radius: 4px;
    width: 22px;
    height: 22px;
}

.checkbox p {
    margin: 0;
    margin-left: 10px;
    font-size: 20px;
}


.mapping-interface {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mapping-checkbox-interface hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #121212;
    /* Or any color you prefer */

    margin-bottom: 20px;

}

.mapping-interface table {
    display: block;
    width: 100%;
    /* Adjust this as needed */
    max-height: 500px;
    /* Adjust the height as needed */
    overflow-y: auto;
    border-collapse: collapse;
    background-color: #a1cbed;
    margin-top: 20px;
}

.mapping-interface thead th {
    background-color: #ddd;
    position: sticky;
    top: 0;


}

.mapping-interface td {
    padding: 10px;
}

.mapping-interface h2 {
    padding: 0;
    margin: 0;
    text-align: left;
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0 30px 0;
}

.input-group label {
    padding: 0;
    margin: 0;
}

.mapping-interface button {
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;
}

.cancelmap {
    background-color: #f44336;
}

.clear-icon {
    position: absolute;
    cursor: pointer;
}

.search-cancel {
    margin-left: 20px;
    background-color: #F44336;
    color: white;
    margin-top: -5px;
}

.search-cancel:hover {
    background-color: #d32b1f;
}


.send-options {
    position: absolute;
    top: 110%;
    right:0;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 100;
    font-size: 16px;
    font-weight: 400;
    width: 290px;
    text-align: left;
}

.send-options ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.send-options li {
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.send-options li:last-child {
    border-bottom: none;

}

.send-options li:last-child span {
    margin-left: 4px;
}

.send-options li:hover {
    background:#eeeaea;

}

.sendToDashboard {
    display: flex;
    flex-direction: row;
    align-items: start;
    /* Aligns items to the start of the container */

}

.sendToDashboard svg,
.sendToRDS svg {
    margin-right: 8px;
}

/* fileList.css */

.overlayLoading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* ensure it's on top of other content */
}

.overlayspinner {
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #1f4f8f;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
#fileList .spinner{
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.checkboxBtns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    margin-left: auto;
}

.check-cancel-btn {
    background-color: #F44336;
}

.check-cancel-btn:hover {
    background-color: #d32b1f
}

.fileName {
    color: black;
    font-size: 12px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.input-group #tableName {
    width: 250px;
}

.headerAddfile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: -10px;

}

.headerAddfile  .addFiles {
    position: relative; /* Required to position the pseudo-element absolutely within the button */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
    border: 1px solid #1f4f8f;
    color: #1f4f8f;
    padding: 5px 10px;
    overflow: hidden; /* Ensures the pseudo-element does not extend outside the button */
    transition: color 0.3s ease; /* Transition for text color */
    z-index: 1;
    margin-left: auto;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    margin-top: 0;
}

.headerAddfile  .addFiles::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%; /* Start with no fill */
    background-color: #1f4f8f; /* Background color to fill on hover */
    transition: height 0.2s ease; /* Animation effect from bottom to top */
    z-index: -1; /* Ensures the text stays on top */
  }
  
  .headerAddfile  .addFiles:hover::after {
    height: 100%; /* Full fill on hover */
  }
  
  .headerAddfile  .addFiles:hover {
    color: white; 
    
  }
  

/* .headerAddfile  .addFiles:hover {
    background-color: #1f4f8f;
    color: white;
} */

.s3label-files {
    
    display: block;
    text-align: left;
  
}

.on-off-toggle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: auto;
    margin-top: auto;

}

.on-off-toggle .toggle-button {
    display: inline-block;
    width: 40px;
    height: 21px;
    background-color: rgb(162, 159, 159);
    border-radius: 25px;
    position: relative;
    cursor: pointer;
}

.on-off-toggle .toggle-button:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.on-off-toggle input[type="checkbox"] {
    display: none;
}

.on-off-toggle input[type="checkbox"]:checked+.toggle-button {
    background-color: #1f4f8f;
}

.on-off-toggle input[type="checkbox"]:checked+.toggle-button:before {
    transform: translateX(20px);
}

.on-off-toggle label {
    font-weight: 600;
    margin-top: auto;
}

.mappingHead {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

/* OverlayStyles.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-content {
    max-width: 400px;
    max-Height: 75%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    cursor: auto;
}
.fileInfo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999999999999 !important;
    display: flex;
    align-items: center;
    overflow: auto;
}
.fileInfo-overlay .fileInfo-overlay-content{
    width: 400px ;
    max-Height: auto;
    background-color: #fff;
    padding: 20px;
    position: relative;
    cursor: auto;
    border-radius: 5px;
    z-index: 1000000;
    transition: 0.8s;
    margin: auto;
    margin-top: 90px;
}
.fileInfo-overlay-content h3 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    
}
.fileInfo-overlay-content p{
text-align: left;
margin-top: 10px;
font-size: 16px !important;
color: #1f4f8f;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"] {
    width: 200px;
    height: 100px;
    display: block;
}

.form-group .input-tag {
    height: auto;
    /* Adjust height for tag input specifically */
  
}

.action-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.view-file-cancel-btn {
    position: absolute;
    top: 90px !important;
    right: 40px !important;
    cursor: pointer;

}

.info-form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.info-form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    margin-left: 0;
}

.info-form-group textarea {
    width: calc(100% - 10px);
    height: 100px;
    display: block;
    resize: none;
    border-radius: 5px;
    padding: 5px;
    color: #1f4f8f;
    border: 1px solid #ccc;

}

.info-form-group .input-tag {
    height: 30px !important;
    width: calc(100% - 10px);
    margin-bottom: 10px;
    padding: 0 5px;
    border-radius: 5px;
    color: #1f4f8f;
    border: 1px solid #ccc;
}

.tag-tile {
    display: inline-flex;
    align-items: center;
    padding: 5px 8px !important;
    margin-right: 10px;
    margin-bottom: 10px !important;
    color: white;
    background-color: #1f4f8f;
    border-radius: 4px;
    cursor: default;
    white-space: nowrap;
    /* Prevents the text from wrapping */
}

.tag-tile .tag-remove-btn {
    margin-left: 7px;
    margin-right: -3px;
    cursor: pointer;
    /* background-color: red; */
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.fileInfo-overlay-content .editinfobtn {
    margin-left: auto;
    font-size: 16px;
}

.form-content {
    display: flex;
    flex-direction: column;
    transition: all .3s ease;

}

/* OverlayStyles.css */

.overlay-s3 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;

    align-items: center;
    justify-content: center;
}

.file-content {
    position: relative !important;
    width: 85vw !important;
    height: 70vh !important;
    overflow: auto;
    display: flex;
    /* This will help you center the content */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    padding: 5px;
    margin-top: 70px !important;
    background-color: white;
    position: relative !important;
}

.file-content img,
.file-content iframe {
    max-width: 100%;
    height: 100% !important;
}

.file-content iframe {
    width: 100%;
    height: 95% !important;
}

.file-content .xls-iframe {
    height: 95% !important;
    width: 100%;
    margin: auto;

}

.clear-icon.view-file-cancel-btn {
    top: 40px;
    right: 40px;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    background-color: #333;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
}

.searchfield {
    width: 60%;
}

.search-bar {
    display: flex;
    align-items: center;
    padding: 0 5px;
    background: #fff;
    margin-top: 5px;
    box-sizing: border-box;
    border-radius: 4px ;
    width: 320px;

}
.input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative; 
    flex-grow: 1; 
  }

.search-bar input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px 5px 10px 0px;
    background: #fff;
    border: 0.5px solid black;
    border-left: none;
    border-radius: 0 4px 4px 0;
    outline: none;
    margin-left: 0;
}
.search-bar input:focus {
    outline: none;
}

.search-bar i {
    cursor: pointer;
    margin-right: 20px;

}

.search-bar #searchCount {
    margin-right: 10px;
    margin-left: 20px;

}
.search-bar .searchIcon{
    border-radius: 0;
    border: 0.5px solid black;
    border-right: none;
    box-shadow:none;
    border-radius:  4px 0 0 4px ;
    height: 25.5px;
    margin-left: 0;
    /* background-color: #d32b1f; */
    margin-top: auto;
}
.search-bar .searchIcon:hover{
    background: #fff;
   cursor: auto;
}

#searchInput {
    width: 100%;

}

.icon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: auto;
    margin: 10px 0;

}
.icon-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px; 
    margin-left: 20px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 4px; /* Circular shape */
    cursor: pointer;
    position: relative;
  
  }
  .icon-card:hover{
    background:#eeeaea;
  }
  
  .icon-card i {
    color: #333; 
    margin-right: 10px;
  }
   .options{
    width:auto;
    padding: 0 10px;
    background-color: #1f4f8f;
    color: white;
  }
  .options:hover{
    background-color: #1f4f8f;
  }
  .options .send-options{
   color: #121212;
  }
.options-icon{
    color: #1f4f8f;
}
.file-list-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
   


}

.file-list-actions .dropDown {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
}

#fileTypeDropdown {
    margin-left: 10px;
}

.form-content .doneFileinfo {
    background-color: #1f4f8f;
    color: white;
    padding: 10px;
    margin-left: auto;
}

.form-content .doneFileinfo:hover {
    color: white;
    background-color: #143e75;
}

.btn-fileinfo {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.textarea-editable {
    pointer-events: auto;
}

.textarea-readonly {
    border:none;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    pointer-events: none;
}

.fileInfo-overlay-content .x-btn {
    position: absolute;
    top: 2px !important;
    right: 10px !important;
   border-radius: 50%;
   
    font-size: 22px !important;
    padding: 10px;
}

.fileInfo-overlay-content .x-btn:hover {
   color: #d32b1f;
    box-shadow: none;
}
.search-file{
    text-align: left;
}
.fileInfo-overlay .filename-editable-input{
    padding: 5px;
    margin-left: 0;
    width: 400px;
    color: #1f4f8f;
}
.editname{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
   
}
.editname p{
    display: inline-block;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

}

.inviteoverlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1050;
    /* Ensure it's above most things */
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.inviteoverlay-overlay-content{
   
    position: relative;
 text-align: left;
    width: 500px;
    max-Height: 75%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    cursor: auto;
    
}
.inviteoverlay-overlay-content h3{
    text-align: left;
    margin-bottom: 10px;
}
.inviteoverlay-overlay-content textarea{
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
}
.inviteoverlay-overlay-content .copybtn{
    margin-left: auto;
    margin-top: -5px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    border-radius: 50%;
    
}
.shared-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
}
.shared-list h3{
    margin-top: auto;
    margin-bottom: auto;
}
.shared-list .invite-user{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
}

.inviteoverlay-overlay-content .copybtn.green {
    color: green;
}

.inviteoverlay-overlay-content .invite-close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: gray;
}
.inviteoverlay-overlay-content .invite-close-button:hover{
    box-shadow: none;
    color: #d32b1f;
}
.text-btn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
#selectall-input{
    margin-left: 10px;
}
#selectall-input-header{
    margin-right: 15px;
}
.shared-list-header{
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
    padding: 10px 5px;
    margin-bottom: 5px;
    background-color: #f8f9fb;
    border-bottom: 2px solid #eaeaea;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.shared-list-header span{
margin-left: 10px;

}
.shared-users-list p{
text-align: center;
font-size: 14px !important;
margin-top: 10px !important;
}
.option-icon-container{
    margin-left: auto;
}
.video-player{
    width: 100%;
}


.file-password-label-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    padding: 5px 0; 
 
    
  }
  .file-label-password{
    margin-left: 0;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .file-password-input{
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 2px 5px !important;
  }


  @media (max-width: 1000px) {
    .file-type,.file-size {
      display: none;
    }
    #fileList .file-header{
        grid-template-columns: 60% 20% 20%;
    }
    #fileList .file-item {
        grid-template-columns: 60% 20% 20%;
    }
 
  }  
  @media (max-width: 600px) {
    .actions-header{
     text-align: right;
    }
  }