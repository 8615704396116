/* DropdownActionsMenu.css */
.LockerDropdownActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 176px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  right: 100%;
}

.LockerDropdownActions.active {
  max-height: 500px;
}

.LockerDropdownActions.top {
  top: auto;
  bottom: 100%;
  transform: translateY(-100px);
}
.LockerDropdownActions ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.LockerDropdownActions .locker-dropdown-menu li {
  list-style: none;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.LockerDropdownActions .locker-dropdown-menu li:hover {
  background-color: #f8f8f8;
}

.LockerDropdownActions .locker-dropdown-menu li:hover .dropdown-icon {
  color: #1f4f8f;
}

.dropdown-icon {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  font-size: 14px;
  color: #adb4d2;
}