/* src/App.css */
.breadcrumbs {
    margin-bottom: 10px;
    margin-top: 70px;
    text-align: left;
    position: sticky;
  }
  
  .breadcrumbs a {
    color: #007bff;
    text-decoration: none;
    margin-right: 5px;
  }
  
  .breadcrumbs a:hover {
    text-decoration: underline;
  }
  
  .breadcrumbs span {
    color: #6c757d;
    margin-right: 5px;
  }
  .greater-than{
    font-size: 12px;
    margin-right: 5px;
    margin-left: 5px;
    color: #007bff;
  }