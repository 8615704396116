/* Header.css */

.databank-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border-radius: 5px;
   
  }
  
  .databank-header p {
  font-size: 25px;

  }
  .databank-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    width: 100%;
   
  }
  
  .databank-nav-btn {
    position: relative;
    background-color: transparent;
    color: #1f4f8f;
    border: 1px solid #1f4f8f;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    opacity: 0.8;
    overflow: hidden; 
    z-index: 1;
  
  }
  
  .databank-nav-btn.active {
    background-color: #1f4f8f; 
    transform: scale(1.1);
    color: white;
    opacity: 1;
  }
  .databank-nav-btn.active .font-icon{
    color: white;
  }
  .databank-nav-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%; /* Start with no fill */
    background-color: #1f4f8f; /* Background color to fill on hover */
    transition: height 0.3s ease; /* Animation effect from bottom to top */
    z-index: -1; /* Ensures the text stays on top */
  }
  
  .databank-nav-btn:hover::after {
    height: 100%; /* Full fill on hover */
  }
  
  .databank-nav-btn:hover {
    color: white; 
    opacity: 01;
  }

  .databank-search-bar {
    margin: 20px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    /* border-bottom: 1px  solid #ccc; */
    max-height: 400px;
    /* transition: all 0.3s; */
    position: static;
    position: relative;
    z-index: 100;
    margin-left: 0;
   
  }
  .databank-search-bar.focused {
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .databank-search-bar.focused.has-results {
    border-radius: 30px 30px 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  .databank-search-container{
    display: flex;
    flex-direction: row;
 
  }
  
  .databank-search-input {
    height: 3rem;
    border: 1px solid #ccc;
    border-left: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-left: 0;
    /* margin-top: 5px; */
    border-radius: 100px;
    height: 39px;
    font-size: 1rem;
    width:673px !important;
    padding-left: 10px;
    /* transition: all 0.2s ease; */
   
  
  }
  .databank-search-bar .databank-searchIcon{
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 100px;
    border-right: none;
    margin-right: 0;
    margin-left: 0;
    margin-top: 5px;
    height: 49px;
    width: 43px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* transition: all 0.2s ease; */
  }
  .databank-search-bar:focus .search-dropdown{
    display: block;
  }
  .databank-icon-search{
    font-size: 20px;
    margin-left: 20px;
  }
  .databank-search-input:focus {
    outline: none; 
    border:none;
    background-color: white;
}


.box-shadow {
    box-shadow: 0 4px 8px rgba(0.2,0.2,0.2,0.3); 
}
.databank-nav span {
margin-left: 5px;
}
.databank-nav .font-icon{
 color: #1f4f8f;
}
.databank-nav-btn:hover .font-icon {
    color: white;  
}
.databank-outlet{
    width: 100%;
}

/* 
.databank-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 730px;
  padding: 0;
  
} */
.search-dropdown{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 350px;
  overflow: auto;
 
  border-top: none;
  border-radius: 0 0 30px 30px;

}
.result-details{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  font-size: 12px; 
margin-top: auto;
margin-bottom: auto;
}

.global-search-results{
  display: grid;
  grid-template-columns: 5% 95% ;
  align-items: flex-start;
  border-radius: 0px;
  margin: 0;
  width: 690px;
  border-top: 1px  solid #ccc;
  cursor: pointer;
 
}
.global-search-results:hover .result-name{
  color: #1f4f8f;
 
}
.global-search-results:hover .result-icon{
  color: #1f4f8f;
}
.result-icon{
  margin-left: 0;
  padding-left: 0;
  margin-right: 8px;
}
.global-search-results:hover {
  background-color: #ddd; 
}
.result-date{
  margin-left: auto;
}
.result-name{
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.databank-search-container.focused {
  border-color: #007bff;
}

.databank-search-container.focused .databank-searchIcon {
   border: 1px solid transparent;
   background-color: white;
}
.databank-breadcrumbs{
  display: flex;
  align-items: flex-start;
  /* background-color: aqua; */
 margin-left: 0; 
 width: 100%;

}
.search-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
}

.advanced-search-content{
  display: flex;
  align-items: flex-start;
  width: 680px;
 
  /* background-color: aqua !important; */
  margin-right: auto;
}
.advanced-search-button {
  margin-left: auto !important;
  margin-bottom: 10px;
  position: relative;
  background-color: transparent;
  color: #1f4f8f;
  border: 1px solid #1f4f8f;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
 font-size: 14px;
  margin:0 0 10px 0;
  margin-left: auto;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden; 
  z-index: 1;
  transition: background-color 0.3s ease;
}

.advanced-search-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%; /* Start with no fill */
  background-color: #1f4f8f; /* Background color to fill on hover */
  transition: height 0.3s ease; /* Animation effect from bottom to top */
  z-index: -1; /* Ensures the text stays on top */
}
.advanced-search-button:hover::after {
  height: 100%; /* Full fill on hover */
}
.advanced-search-button:hover{
  color:white;
}



@media screen and (max-width: 1000px) {
  .databank-search-container {
    width: 100%;
  }
  .databank-search-bar{
    width: calc(100% - 20px);
    margin-left: 0;
  }
  .databank-nav{
    gap: 20px;
  }
  .advanced-search-content{
    width: 660px;
  }
}
@media screen and (max-width: 950px) {
  .databank-nav-btn {
    font-size: 14px;
    padding: 8px 10px;
  }
  .advanced-search-content{
    width: 600px;
  }
}
@media screen and (max-width: 870px) {
  .advanced-search-content{
    width: 530px;
  }
}
@media screen and (max-width: 800px) {
  .advanced-search-content{
    width:auto;
  }
}

@media screen and (max-width: 650px) {
  .databank-nav-btn {
    font-size: 12px;
    padding: 8px 10px;
  }
  .databank-nav{
    gap: 10px;
   padding: 0 10px 0 10px;
   width: calc(100% - 20px);
  }
}
@media screen and (max-width: 650px) {
  .databank-nav{
    display: flex;
   flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
   
  }
}