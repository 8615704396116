.link-search-content{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 20px);
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}
.search-bar{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.files-search-results{
   
    margin-top: 50px;
}
.files-search-content{
    width: 100%;
}
.files-search-content input{
    width: 200px !important;
}
.files-search-content label{
  color: #1f4f8f;
  margin: auto;
  margin-right: 10px;
}
.file-search-results-header{
    display: grid;
    grid-template-columns: 83% 17% !important;
   margin-bottom: -20px !important;
   
}
.file-search-item{
    display: grid;
    grid-template-columns: 83% 17% !important;
    margin-left: -20px !important;
  
}
.file-search-result-details{
   
    width: 100%;
}
.file-search-item-content{
    margin: 0;
}
.file-search-result-date{
    font-size: 12px;
    /* margin-right: auto !important; */
    margin-left: 10px !important;
}
.file-search-last-updated{

    margin-left: 10px;
    width: 100%;
    padding: 0;
    
}
.file-search-pagination{
    /* background-color: aqua; */
    margin-top: -20px;
}
.file-search-item-icon{
    margin-left: 5px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    height: 30px;
    width: 30px;
    font-size: 20px;
  
}
.file-search-item-icon .file-search-icon{
    margin-top: 5px !important;
}
